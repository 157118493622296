import logo from './logo.svg';
import './App.css';



//INSTRUCTIONS
/*

Create a new React
npx create-react-app my-app
cd my-app
npm start

Install React Router Dom
npm install react-router-dom

Add pages folder in src folder

Add components folder in src folder



*/


function App() {

	var hideReferral = false;
	var showListings = false;

	var referallCodes = {
		"phil": {
			"isActive": true,
			"code": "phil187207"
		},
		"brendan": {
			"isActive": false,
			"code": "brendan34688"
		},
		"ryan": {
			"isActive": false,
			"code": "ryan62633"
		},
		"jonny": {
			"isActive": false,
			"code": "jonny282362"
		}
		
	}

	//get code where isActive is true
	var referralCode = "";
	for (var key in referallCodes) {
		if (referallCodes[key].isActive) {
			referralCode = referallCodes[key].code;
		}
	}




  return (
    <div className="App" >

		<h1>Save up to $1,000 with my referral code</h1>
		
		<h3>Use the links below to go to the Tesla website where you can save $1,000 on a purchase of your new Telsa.</h3>

		
		
		{!hideReferral && (
			<>
				
				<a href={`https://www.tesla.com/modely/design?referral=${referralCode}&redirect=no`} className="referral-link">
					<div className="product-card model-y">
						Model Y
						<div className="order-now">Order Now</div>
					</div>
				</a>
				<a href={`https://www.tesla.com/model3/design?referral=${referralCode}&redirect=no`} className="referral-link">
					<div className="product-card model-3">
						Model 3
						<div className="order-now">Order Now</div>
					</div>
				</a>
				<a href={`https://www.tesla.com/models/design?referral=${referralCode}&redirect=no`} className="referral-link">
					<div className="product-card model-s">
						Model S
						<div className="order-now">Order Now</div>
					</div>
				</a>
				<a href={`https://www.tesla.com/modelx/design?referral=${referralCode}&redirect=no`} className="referral-link">
					<div className="product-card model-x">
						Model X
						<div className="order-now">Order Now</div>
					</div>
				</a>

				<p className="disclaimer">Tesla is currently offering up to $1,000 off your purchase of a new Tesla using a referral code. This website has no affiliation to Tesla. By clicking this link you will be re-drirected to the Tesla website where you can place the order and my referral code with your discount will be automatically applied. Tesla can change this program at any time, please check the Tesla website to ensure your discount is applied. </p>
			</>
		)}

		{showListings && (
			<>
			
				<a href={`https://www.tesla.com/modely/design?referral=${referralCode}&redirect=no`} className="referral-link">
					<div className="product-card hidden">
						<br/>
						<div className="order-now blue">Visit Tesla</div>
					</div>
				</a>

				<p className="action-text">Tesla is currently offering up to $1,000 off your purchase of a new Tesla using a referral code. Check out some great perks to owning a Tesla and some must have accessories below.  </p>
				<p className="disclaimer">Tesla is currently offering up to $1,000 off your purchase of a new Tesla using a referral code. This website has no affiliation to Tesla. By clicking this link you will be re-drirected to the Tesla website where you can place the order and my referral code with your discount will be automatically applied. Tesla can change this program at any time, please check the Tesla website to ensure your discount is applied. </p>

				<div className = "product-grid">
					<p className="group-title">Software Update 2024.16</p>
					<p class="body-text">Tesla's latest software update, version 2024.16, brings significant enhancements to Autopilot, making lane-keeping and lane changes smoother while improving the detection of traffic signals and stop signs. These changes move Tesla closer to full autonomy, reducing the need for driver intervention.</p>

					<p class="body-text">The update also boosts the infotainment system with more streaming apps, gaming features, and faster performance. Integration with platforms like Spotify and YouTube is improved, offering a more connected experience for drivers and passengers alike.</p>

					<p class="body-text">Additionally, the update focuses on energy efficiency and customization. New climate control settings adjust based on the number of passengers, while improved energy-saving modes optimize battery usage to extend the vehicle’s range. Tesla continues to refine the driving experience through over-the-air updates.</p>

					<p class="body-text">The latest Tesla software update, version 2024.16, is truly a game-changer! The improvements to Autopilot are immediately noticeable—lane-keeping is more precise, and lane changes feel incredibly smooth and intuitive. It's exciting to see how much closer Tesla is getting to full autonomy, making driving safer and more relaxing.</p>

					<p class="body-text">The enhancements to the infotainment system are another huge plus. The addition of more streaming apps and gaming options has made road trips more enjoyable, and the faster, more responsive interface is a welcome upgrade. Integrating third-party apps like Spotify and YouTube is now seamless, making it easier to stay connected and entertained while on the go.</p>

					<p class="body-text">What really stands out, though, is the focus on efficiency. The new energy-saving features are fantastic, helping extend the range without compromising performance. Tesla's ability to roll out such impactful updates over-the-air continues to impress, keeping the driving experience fresh and forward-thinking. This update is a win for Tesla owners!</p>
				</div>
				<div className = "product-grid">
					<p className="group-title">Chargers</p>

					<p className="product">Tesla Wall Charger</p>
					<div className="product-suggestion-card charger">	
					</div>

					<a href="https://www.amazon.com/Tesla-Wall-Connector-Electric-Vehicle/dp/B0BWSK35WH/ref=asc_df_B0BWSK35WH/?tag=hyprod-20&linkCode=df0&hvadid=693634200425&hvpos=&hvnetw=g&hvrand=6016916845396081321&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9004229&hvtargid=pla-2004500297984&psc=1&mcid=16209a38d2e63d7081461f9dcd1a38e3" className="third-party-link blue">
						Order from Amazon
					</a>

					<p className="group-title">Floor Mats</p>

					<p className="product">3D Mats</p>
					<div className="product-suggestion-card threedmat">	
					</div>

					<a href="https://3dmatsusa.com/collections/custom-fit-car-floor-liners" className="third-party-link blue">
						Order from 3D Mats
					</a>

					<p className="group-title">Screen Protectors</p>

					<p className="product">No Glare Protector</p>
					<div className="product-suggestion-card screen">	
					</div>

					<a href="https://www.amazon.com/Spigen-Tempered-Screen-Protector-Designed/dp/B084BNC3FG/ref=sr_1_3?dib=eyJ2IjoiMSJ9.GB52WKZZXY9GPmiQNrC-ih7xiEt9pBTLzDOzzZz-P6I53q_qrQnBw3ZL59sFB1s0o--6QfitPRWCoeSicO9R_LU_p1_YY-A6kEPIQrT0PniuJ4tpRBIoiS0R206TEf-3xPnSj_DShp6WVc7-EVmQjmfwFGslscNgLXp62vQLo7rr1fDr1_Y_KUpolXnhO_mbULwOhMCXR2BkFXix-UH_u-C1gnqDlVTpJJaNdaYqOXk.JW6dcH0QLTBJ33y0_UpMKMTLGpQV3-9drWBe0ajAOBY&dib_tag=se&hvadid=695300759492&hvdev=c&hvexpln=67&hvlocphy=9004229&hvnetw=g&hvocijid=7294686750756063301--&hvqmt=e&hvrand=7294686750756063301&hvtargid=kwd-301536496694&hydadcr=7276_13212561&keywords=tesla%2Bscreen%2Bprotector&qid=1726948953&sr=8-3&th=1" className="third-party-link blue">
						Order from Amazon
					</a>

				</div>
			</>
		)}
	
		
		
		
		
    </div>
  );
}

export default App;
